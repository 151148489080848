import React, { useEffect, useState } from "react";
import "../App.css";
import "../Desktop.css";
import { useMediaQuery } from "react-responsive";
import TPGStatusSidebar from "../TPGStatusSidbar";
import TopBar from "../TopBar";
import Table from "react-bootstrap/Table";
import { css } from "@emotion/css";
import online_icon from "../assets/images/tpg/online.png";
import offline_icon from "../assets/images/tpg/offline.png";
import alert_icon from "../assets/images/tpg/alert.png";
import dormant_icon from "../assets/images/tpg/dormant.png";
import mesh_icon from "../assets/images/tpg/mesh.png";
import online_on_map_icon from "../assets/images/tpg/online-map.png";
import offline_on_map_icon from "../assets/images/tpg/offline-map.png";
import alert_on_map_icon from "../assets/images/tpg/alert-map.png";
import dormant_on_map_icon from "../assets/images/tpg/dormant-map.png";
import mesh_on_map_icon from "../assets/images/tpg/mesh-map.png";

const fetchNetworkIDs = async () => {
	return await fetch(`https://merakiwifi.app/api/TPG/FetchNetworkIDs`)
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network IDs Errors: ", data.errors[0]);
				return [];
			}
			return data;
		});
};
const fetchNetworkFloorPlans = async (networkID) => {
	return await fetch(`https://merakiwifi.app/api/TPG/FetchNetworkFloorPlans`, {
		headers: {
			networkid: networkID,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Network Floor Plan Errors: ", data.errors[0]);
				return data.errors[0];
			}
			// console.log(data);
			return data[0];
		});
};

const fetchOrganizationDevices = async () => {
	return await fetch(`https://merakiwifi.app/api/TPG/FetchOrganizationDevices`)
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Organization devices Errors: ", data.errors[0]);
				return data.errors[0];
			}
			// console.log(data);
			return data;
		});
};

const fetchDeviceStatuses = async () => {
	return await fetch(`https://merakiwifi.app/api/TPG/FetchDeviceStatuses`)
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Device Statuses Errors: ", data.errors[0]);
			}
			return data;
		});
};

const fetchWavespotAnalytics = async () => {
	return await fetch(`https://merakiwifi.app/api/TPG/FetchWavespotAnalytics`)
		.then((response) => response.json())
		.then((data) => {
			if (data.errors) {
				console.log("Fetch Wavespot Analytics Errors: ", data.errors[0]);
			}
			return data;
		});
};

function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

const alphanumaricSorter = (a, b) => {
	return a.name.toString().localeCompare(b.name.toString(), "en", { numeric: true });
};

const apNameSorter = (ap1, ap2) => {
	return parseInt(ap1.name.split("AP")[1]) - parseInt(ap2.name.split("AP")[1]);
};

const TPGStatusDashboard = () => {
	const [networkIDs, setNetworkIDs] = useState([]);
	const [floorPlans, setFloorPlans] = useState({});
	const [devices, setDevices] = useState({});
	const [deviceStatuses, setDeviceStatuses] = useState([]);
	const [wavespotAnalytics, setWavespotAnalytics] = useState();

	useEffect(() => {
		const getNetworkIDs = async () => {
			let ids = await fetchNetworkIDs();
			ids.sort((id1, id2) => {
				return id1.name.localeCompare(id2.name);
			});
			setNetworkIDs(ids);
		};
		getNetworkIDs();
		const getDeviceStatuses = async () => {
			setDeviceStatuses(await fetchDeviceStatuses());
		};
		getDeviceStatuses();
		// const getWavespotAnalytics = async () => {
		// 	setWavespotAnalytics(await fetchWavespotAnalytics());
		// };
		// getWavespotAnalytics();
	}, []);

	useEffect(() => {
		//Get network floor plans for each network.
		const getFloorPlans = async () => {
			let plans = {};
			//for some reson a forEach() doesn't work here. It just makes all the requests at once.
			for (let netID of networkIDs) {
				// await sleep(500);
				plans[netID.id] = (await fetchNetworkFloorPlans(netID.id)) || "none";
				// if (!plans[netID.id]) {
				// 	plans[netID.id] = "none";
				// }
				// console.log("added new plan");
				setFloorPlans(() => ({
					...plans,
				}));
			}
		};
		getFloorPlans();
	}, [networkIDs]);

	useEffect(() => {
		console.log("reload");
		// if (devices && deviceStatuses) {
		// 	// console.log("devices: ", deviceStatuses);
		// 	networkIDs.forEach((netID) => {
		// 		let deviceMac = devices[netID.id].filter((device) =>
		// 			device.model.includes("MX")
		// 		)[0].mac;
		// 		console.log(
		// 			deviceStatuses.filter(
		// 				(deviceStatus) => deviceStatus.mac === deviceMac
		// 			)[0].status
		// 		);
		// 	});
		// }
	}, [devices, deviceStatuses, floorPlans]);

	useEffect(() => {
		//get all APs/Devices in the organization
		const getDevices = async () => {
			let networkDevices = {};
			let allDevices = await fetchOrganizationDevices();
			for (let device of allDevices) {
				if (parseInt(device.name.split("AP")[1])) {
					device["apNumber"] = parseInt(device.name.split("AP")[1]);
				}
				if (networkDevices[device.networkId]) {
					networkDevices[device.networkId] = [...networkDevices[device.networkId], device];
				} else {
					networkDevices[device.networkId] = [device];
				}
			}
			//Sort the devices Alphanumarically
			// for (let networkName of Object.keys(networkDevices)) {
			// 	let devices = networkDevices[networkName];
			// 	devices.sort(apNameSorter);
			// 	networkDevices[networkName] = devices;
			// }
			setDevices(networkDevices);
		};
		getDevices();
	}, []);

	return (
		<div className="App">
			<TPGStatusSidebar></TPGStatusSidebar>
			<div className="MainPanel">
				{/* <TopBar
					fetchOrgIDs={fetchOrgIDs}
					fetchAPIKeyHolder={fetchAPIKeyHolder}
					apiKeyHolder={apiKeyHolder}
					setApiKeyHolder={setApiKeyHolder}
					apiKey={apiKey}
					setApiKey={setApiKey}
				></TopBar> */}
				<div className="TPGStatusTable">
					<Table>
						<thead>
							<tr>
								<th>
									<span>Site</span>
								</th>
								<th>
									<span>Analytics</span>
								</th>
								<th>
									<span>Site Map</span>
								</th>
								<th>
									<span>MX</span>
								</th>
								<th>
									<span>MS</span>
								</th>
								<th>
									<span>AP01</span>
								</th>
								<th>
									<span>AP02</span>
								</th>
								<th>
									<span>AP03</span>
								</th>
								<th>
									<span>AP04</span>
								</th>
								<th>
									<span>AP05</span>
								</th>
								<th>
									<span>AP06</span>
								</th>
								<th>
									<span>AP07</span>
								</th>
								<th>
									<span>AP08</span>
								</th>
								<th>
									<span>AP09</span>
								</th>
								<th>
									<span>AP10</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{networkIDs.map((netID) => {
								if (Object.keys(devices).length && deviceStatuses.length && Object.keys(floorPlans).length && networkIDs.length) {
									// console.log(Object.keys(devices).length, deviceStatuses.length, Object.keys(floorPlans).length, networkIDs.length);
									// console.log("WSA: ", wavespotAnalytics);
									let wavespotSite;
									if (wavespotAnalytics) {
										wavespotSite = wavespotAnalytics.filter(
											(site) => site.location_name.split("-C")[0].replace("-", "#") === netID.name
										)[0];
									}
									// if (!wavespotSite) {
									// 	return "Not Avalible";
									// }

									let networkAPs = devices[netID.id].filter((device) => device.model.includes("MR"));
									let networkRouter = devices[netID.id].filter((device) => device.model.includes("MX"))[0];
									let networkSwitch = devices[netID.id].filter((device) => device.model.includes("MS"));
									// if (!networkSwitch.length) {
									// 	console.log("no switch in: ", devices[netID.id]);
									// }
									// console.log("switches: ", networkSwitch);
									if (networkSwitch.length > 1) {
										//for each switch, check if its status is dormant, and if it is, remove it/return false
										networkSwitch = networkSwitch.filter((switchDevice) => {
											return deviceStatuses.some((device) => [device.mac == switchDevice.mac]).status != "dormant";
										})[0];
									} else {
										networkSwitch = networkSwitch[0];
									}
									// console.log("new switches: ", networkSwitch);

									// console.log(networkSwitch);
									let apStatuses = {};
									devices[netID.id].forEach((device) => {
										//for each device, if its an ap, find its status and put the ap mac and status in a dict
										if (device.model.includes("MR")) {
											apStatuses[device.mac] = deviceStatuses.filter((deviceStatus) => deviceStatus.mac === device.mac)[0].status;
										}
									});
									// deviceStatuses.forEach((status) => {
									// 	if (!status.mac) {
									// 		console.log("no mac on the device", status);
									// 	}
									// });
									// console.log("the network switch is: ", networkSwitch);
									// if (!("mac" in Object.keys(networkSwitch))) {
									// 	console.log("network Swtich: ", networkSwitch);
									// }
									let router_icon_path;
									if (networkRouter) {
										switch (deviceStatuses.filter((status) => status.mac === networkRouter.mac)[0].status) {
											case "online":
												router_icon_path = online_icon;
												break;
											case "offline":
												router_icon_path = offline_icon;
												break;
											case "alerting":
												router_icon_path = alert_icon;
												break;
											case "dormant":
												router_icon_path = dormant_icon;
												break;
										}
									}
									let switch_icon_path;
									if (networkSwitch) {
										switch (deviceStatuses.filter((status) => status.mac === networkSwitch.mac)[0].status) {
											case "online":
												switch_icon_path = online_icon;
												break;
											case "offline":
												switch_icon_path = offline_icon;
												break;
											case "alerting":
												switch_icon_path = alert_icon;
												break;
											case "dormant":
												switch_icon_path = dormant_icon;
												break;
										}
									}
									return (
										<tr key={netID.id}>
											<td>{netID.name}</td>
											<td>{wavespotSite ? wavespotSite.status : "Unknown"}</td>
											<td>
												{floorPlans[netID.id] !== "none" && floorPlans[netID.id]
													? "yes"
													: floorPlans[netID.id] === "none"
													? "no"
													: "Loading..."}
											</td>
											<td>{networkRouter ? <img src={router_icon_path} /> : "none"}</td>
											<td>{networkSwitch ? <img src={switch_icon_path} /> : "none"}</td>
											{[...Array(10).keys()].map((apIdx) => {
												let ap = networkAPs.find((device) => device.apNumber === apIdx + 1);
												if (!ap) {
													return <td></td>;
												}
												let icon_path;
												if (
													floorPlans[netID.id] &&
													floorPlans[netID.id] !== "none" &&
													floorPlans[netID.id].devices.some((device) => device.mac === ap.mac)
												) {
													// console.log(ap, apStatuses[ap.mac]);
													switch (apStatuses[ap.mac]) {
														case "online":
															if (!ap.lanIp) {
																icon_path = mesh_on_map_icon;
																break;
															} else {
																icon_path = online_on_map_icon;
																break;
															}
														case "offline":
															icon_path = offline_on_map_icon;
															break;
														case "alert":
															icon_path = alert_on_map_icon;
															break;
														case "dormant":
															icon_path = dormant_on_map_icon;
															break;
													}
												} else {
													switch (apStatuses[ap.mac]) {
														case "online":
															if (!ap.lanIp) {
																icon_path = mesh_icon;
																break;
															} else {
																icon_path = online_icon;
																break;
															}
														case "offline":
															icon_path = offline_icon;
															break;
														case "alert":
															icon_path = alert_icon;
															break;
														case "dormant":
															icon_path = dormant_icon;
															break;
													}
												}
												return (
													<td key={ap.mac} title={ap.name}>
														<img src={icon_path} />
													</td>
												);
											})}
										</tr>
									);
								}
								// else {
								// 	console.log(
								// 		devices,
								// 		deviceStatuses,
								// 		Object.keys(floorPlans).length,
								// 		networkIDs.length
								// 	);
								// }
							})}
						</tbody>
					</Table>
				</div>
			</div>
		</div>
	);
};

export default TPGStatusDashboard;
